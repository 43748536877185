

























import { Component, Vue } from "vue-property-decorator";
import gEmpty from "@/components/g-empty.vue";
import gRoomList from "@/components/g-room-list.vue";
import EventBus from "@/assets/js/EventBus";
import { State } from "vuex-class";
import { guideServer } from "@/assets/js/Request";

@Component({
  components: {
    gEmpty,
    gRoomList,
  },
})
export default class SGCollect extends Vue {
  private list = [];
  private loading = true;
  private page = 1;
  private limit = 12;
  private type = 1;
  private getMoreTag = true;
  private noMoreTag = false;

  @State("socketId") uid: any;
  @State updataTag: any;

  toGuideIndex(): void {
    EventBus.$emit("toGuideIndex");
    this.$socket?.dispatchEvent("toGuideIndex", "");
  }

  getCollectList(): void {
    console.log("getCollectList");
    this.$get(guideServer + "/shopping_guide/get_user_collect", {
      uid: this.uid || 0,
      page: this.page,
      limit: this.limit,
      product_type: this.type,
    }).then((res: any) => {
      let data = res.data.data;
      if (this.page > 1) {
        this.list = this.list.concat(data.data);
      } else {
        this.list = data.data;
      }

      this.loading = false;
      this.getMoreTag = false;
      this.noMoreTag = data.total <= this.page * this.limit;
    });
  }

  getMore(): void {
    if (this.getMoreTag) {
      return;
    }
    if (this.noMoreTag) {
      this.$showToast("没有更多数据了");
      return;
    }
    this.getMoreTag = true;
    this.page++;
    this.getCollectList();
  }

  mounted(): void {
    // if (!this.updataTag) {
    this.getCollectList();
    // }
  }

  activated() {
    // console.warn("Collect activated.");
    // if (this.updataTag) {
    // this.page = 1;
    // this.getCollectList();
    // this.$store.commit("changeUpdateTag", false);
    // }
  }
}
